// 合同状态
export const contractStatusObj = {
  0: "新建",
  1: "正常",
  2: "退租",
  3: "作废",
  4: "待执行",
}

// 审批状态
export const approveStatusObj = {
  1: "签约待审核",
  2: "签约待修改",
  3: "签约审核成功",
  4: "变更待审核",
  5: "变更待修改",
  6: "变更审核成功",
  7: "退租待审核",
  8: "退租待修改",
  9: "退租审核成功",
  10: "作废待审核",
  11: "作废待修改",
  12: "作废审核成功",
}


export const preorderStatusObj = {
  "-1": "新建",
  0: "未付款",
  1: "预定",
  2: "正式签约",
  3: "解约",
  4: "作废",
}

// 费用类型
export const costTypeOpt = [{
    value: 1,
    text: "租金",
  },
  {
    value: 2,
    text: "物业费",
  },
  {
    value: 3,
    text: "租房押金",
  },
  {
    value: 4,
    text: "物业押金",
  },
  {
    value: 5,
    text: "水电费",
  },
  {
    value: 6,
    text: "钱包",
  },
  {
    value: 7,
    text: "其他",
  },
]

// 退租原因
export const caseTypeOpt = [{
    value: 1,
    text: "正常到期",
  },
  {
    value: 2,
    text: "价格因素",
  },
  {
    value: 3,
    text: "物业服务",
  },
  {
    value: 4,
    text: "交通不便",
  },
  {
    value: 5,
    text: "设置配置",
  },
  {
    value: 6,
    text: "卫生环境",
  },
  {
    value: 7,
    text: "楼宇质量",
  },
  {
    value: 8,
    text: "公司扩张",
  },
  {
    value: 9,
    text: "经营不善",
  },
]


export const pricingUnitList = {
  1: "元/㎡/天",
  2: "元/㎡/月",
  3: "元/工位/天",
  4: "元/工位/月",
  5: "元/日",
  6: "元/月",
  7: "元/小时"
}