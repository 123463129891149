<template>
  <div id="reserveInfo">
    <Back title="预定详情"></Back>
    <main>
      <!-- 客户信息 -->
      <section class="block-item">
        <div class="block-title van-hairline--bottom">
          <div class="title-left"><i></i>客户信息</div>
          <div class="title-right"></div>
        </div>
        <div class="block-content">
          <p>
            <span class="info-color">客户名称</span
            ><span>{{
              reserveInfo &&
              reserveInfo.tenantDto &&
              reserveInfo.tenantDto.tenantName
            }}</span>
          </p>
          <p>
            <span class="info-color">客户联系人</span
            ><span>{{
              reserveInfo &&
              reserveInfo.tenantDto &&
              reserveInfo.tenantDto.contactUserName
            }}</span>
          </p>
          <p>
            <span class="info-color">客户手机号</span
            ><span>{{
              reserveInfo &&
              reserveInfo.tenantDto &&
              reserveInfo.tenantDto.contactUserMobile
            }}</span>
          </p>
        </div>
      </section>
      <!-- 房源信息 -->
      <section class="block-item">
        <div class="block-title van-hairline--bottom">
          <div class="title-left"><i></i>房源信息</div>
          <div class="title-right"></div>
        </div>
        <div class="block-content">
          <p>
            <span class="info-color">楼宇名称</span
            ><span>{{
              reserveInfo &&
              reserveInfo.resourceDtos[0] &&
              reserveInfo.resourceDtos[0].unitName
            }}</span>
          </p>
          <p>
            <span class="info-color">房源名称</span
            ><span>{{
              reserveInfo &&
              reserveInfo.resourceDtos[0] &&
              reserveInfo.resourceDtos[0].resourceName
            }}</span>
          </p>
          <p>
            <span class="info-color">房源面积</span
            ><span
              >{{
                reserveInfo &&
                reserveInfo.resourceDtos[0] &&
                reserveInfo.resourceDtos[0].area
              }}㎡</span
            >
          </p>
        </div>
      </section>
      <!-- 定金明细 -->
      <section class="block-item">
        <div class="block-title van-hairline--bottom">
          <div class="title-left"><i></i>定金明细</div>
          <div class="title-right"></div>
        </div>
        <div class="block-content">
          <p>
            <span class="info-color">定金金额</span
            ><span>{{ reserveInfo.frontMoney }}元</span>
          </p>
          <p>
            <span class="info-color">定金有效期</span
            ><span
              >{{ formatterDate(reserveInfo.effDate, "yyyy-MM-dd") }}~{{
                formatterDate(reserveInfo.expDate, "yyyy-MM-dd")
              }}</span
            >
          </p>
        </div>
      </section>
      <!-- 订单状态 -->
      <section class="block-item">
        <div class="block-title van-hairline--bottom">
          <div class="title-left"><i></i>订单状态</div>
          <div class="title-right"></div>
        </div>
        <div class="block-content">
          <p>
            <span class="info-color">订单状态</span
            ><span>{{ preorderStatusObj[reserveInfo.preorderStatus] }}</span>
          </p>
          <p>
            <span class="info-color">逾期状态</span>
            <span v-if="reserveInfo.overdue">已逾期</span>
            <span v-else>未逾期</span>
          </p>
          <p>
            <span class="info-color">备注信息</span
            ><span>{{ reserveInfo.remark }}</span>
          </p>
        </div>
      </section>
    </main>
  </div>
</template>

<script>
import Back from "@/components/back";
import { getReserveInfo } from "@/api/contract/contract";
import { formatterDate } from "@/utils/utils";
import { preorderStatusObj } from "@/db/contract";
export default {
  components: { Back },
  data() {
    return {
      formatterDate,
      preorderStatusObj,
      preorderId: "",
      reserveInfo: "",
    };
  },
  created() {
    this.preorderId = this.$route.query.preorderId;
    this.getReserveInfo();
  },
  methods: {
    // 【请求】预定详情
    getReserveInfo() {
      let data = this.preorderId;
      getReserveInfo(data).then((res) => {
        if (res.data) {
          this.reserveInfo = res.data;
        }
        console.log("this.reserveInfo: ", this.reserveInfo);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/styles/theme";
#reserveInfo {
  height: 100%;
  display: flex;
  flex-direction: column;
}

main {
  flex: 1;
  overflow: auto;
}

// 文本模块
.block-item {
  border-top: $bg-color 0.15rem solid;
  display: flex;
  flex-direction: column;
  padding: 0 0.16rem 0.16rem 0.16rem;
  box-sizing: border-box;
  > .block-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.1rem 0;
    box-sizing: border-box;
    .title-left {
      display: flex;
      align-items: center;
      > i {
        display: inline-block;
        background: $theme-color;
        width: 0.04rem;
        height: 0.15rem;
        border-radius: 0 0.04rem 0.04rem 0;
        margin-right: 0.1rem;
      }
    }
  }
  // 内容区上边距
  .block-content {
    margin-top: 0.1rem;
    > p {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0.02rem 0;
    }
  }
}
</style>